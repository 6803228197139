<template>
    <div class="action-updated-profile">
        <template>
            <div class="message">
                <div class="message-top">
                    <img class="m-r-8" src="@/assets/images/icons/hello.png" width="24px" height="24px" />
                    <span>우주메리를 시작합니다!</span>
                </div>
                <div class="message-content">
                    <p>
                        서비스 이용을 위해서는 결제가 필요합니다. 본인에게 맞는 만남권을 구매하시면 자동으로
                        매칭매니저와 연결됩니다:)
                    </p>
                </div>
                <div class="buttons">
                    <BottomButton @click="goMatchPreference" class="m-b-8" :nonFixed="true" label="만남권 알아보기" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ActionUpdatedProfile',
    methods: {
        goMatchPreference() {
            this.$stackRouter.push({
                name: 'EditStylePage',
            })
        },
        goCertCenter() {
            this.$stackRouter.push({
                name: 'CertificatePage',
            })
        },
    },
}
</script>

<style scoped lang="scss">
.action-updated-profile {
    padding: 0 16px;
}
.message-top {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.message-content {
    line-height: 26px;
    color: black;

    p {
        margin-bottom: 16px;
    }
}
.buttons {
    margin-bottom: 16px;

    ::v-deep .bottom-button {
        button {
            height: 40px;
        }
    }
}
</style>
